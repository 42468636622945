import React from "react";
import debounce from "lodash/debounce";
import FormattedPrice from "components/elements/formatted-price";
import Link from "components/elements/link";
import Preloader from "components/elements/preloader";
import Slider from "components/elements/slider";
import Svg from "components/elements/svg";
import Api from "services/api";
import AuthService from "services/auth";

class RecentlySold extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      properties: [],
      loading: true,
      currentProperty: 0,
      perSlide: 3,
    };
    this.api = new Api();
    this.setItemsPerSlide = debounce(this.setItemsPerSlide.bind(this), 10);
  }

  componentDidMount() {
    const { pusher } = window;
    this.socket = pusher.subscribe("properties-channel");
    this.socket.bind("property.status.updated", (event) => {
      console.log("recently-sold property.status.updated", event);
      if (
        event.data.property.newStatus === "sold" &&
        !event.data.property.isHidden
      ) {
        const { properties } = this.state;
        properties.unshift(event.data.property);
        this.setState({ properties });
      }
    });
    this.fetchProperties(1);
    this.setItemsPerSlide();
    window.addEventListener("resize", this.setItemsPerSlide);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setItemsPerSlide);
    if (this.socket) {
      this.socket.unbind();
    }
  }

  setItemsPerSlide() {
    this.setState({ perSlide: window.innerWidth > 992 ? 3 : 1 });
  }

  fetchProperties(page) {
    AuthService.getTokens().then((tokens) => {
      const data = {
        page,
        perPage: 15,
        filters: {
          status: "sold",
        },
      };
      this.api.get("/properties", data, tokens).then((response) => {
        if (response.success && response.properties.length > 0) {
          this.setState({
            properties: response.properties,
            loading: false,
          });
        }
      });
    });
  }

  handleSlideChange(index) {
    if (this.state.perSlide === 1) {
      this.setState({ currentProperty: index });
    }
  }

  render() {
    const { properties, currentProperty, perSlide } = this.state;
    return (
      <div className="home__recently-sold">
        <h3 className="section-heading">
          Recently sold <strong>properties</strong>
        </h3>
        <div className="home__recently-sold__inner container">
          <Preloader theme="transparent" show={this.state.loading} />
          <div
            className="home__recently-sold__preview"
            style={{
              backgroundImage: `url(${
                properties[currentProperty]
                  ? properties[currentProperty].thumbnail
                  : ""
              })`,
            }}
          />
          <div className="home__recently-sold__properties">
            {properties.length > perSlide && (
              <button
                onClick={() => this.slider.changeSlide(-1)}
                className="arrow arrow-left"
              >
                <Svg src="chevron-up" className="icon" />
              </button>
            )}
            <Slider
              vertical={perSlide > 1}
              infinite
              onSlideChange={(index) => this.handleSlideChange(index)}
              perSlide={perSlide}
              ref={(slider) => {
                this.slider = slider;
              }}
            >
              {properties.map((property, index) => (
                <div
                  role="button"
                  tabIndex="0"
                  key={property.id}
                  className={`home__recently-sold__property${
                    currentProperty === index ? " active" : ""
                  }`}
                  onClick={() => this.setState({ currentProperty: index })}
                >
                  <div className="property-title">{property.title}</div>
                  <div className="property-details">
                    <div className="price property-details__item">
                      <FormattedPrice price={property.currentPrice} />
                    </div>
                    <div className="bids property-details__item">
                      {property.numberOfBids}{" "}
                      {property.numberOfBids === 1 ? "bid" : "bids"}
                    </div>
                    <div className="permalink property-details__item">
                      <Link to={`properties/${property.slug}`}>
                        View property »
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            {properties.length > perSlide && (
              <button
                onClick={() => this.slider.changeSlide(1)}
                className="arrow arrow-right"
              >
                <Svg src="chevron-down" className="icon" />
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default RecentlySold;
