import React from "react";
import debounce from "lodash/debounce";
import withAuth from "components/core/with-auth";
import Link from "components/elements/link";
import SVG from "components/elements/svg";
import TextContent from "components/elements/text-content";
import Video from "components/elements/video";
import currentUserPropType from "helpers/prop-types/current-user";

class OurWay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoPlaying: false,
      showSlider: false,
    };
    this.showSlider = debounce(this.showSlider.bind(this), 10);
  }

  componentDidMount() {
    this.showSlider();
    window.addEventListener("resize", this.showSlider);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.showSlider);
  }

  showSlider() {
    this.setState({ showSlider: window.innerWidth <= 768 });
  }

  changeSlide(interval) {
    this.slider.changeSlide(interval);
  }

  handleVideoToggle = (isPlaying) => {
    this.setState({ videoPlaying: isPlaying });
  };

  render() {
    const { videoPlaying } = this.state;
    const isLoggedIn = !!this.props.user.id;

    return (
      <section className="home__our-way">
        <div className="container">
          <div className="row">
            <div className="col-md-6 home__our-way__content">
              <p className="section__title">
                <strong>Full verification</strong> of all our members
              </p>

              <div className="home__our-way__reasons">
                <TextContent>
                  <p>
                    We conduct a thorough verification process of all our
                    members ensuring that only genuine people use our site.
                    Security and integrity are fundamentally important to us.
                  </p>
                </TextContent>
              </div>
              <div className="home__our-way__buttons">
                <div className="button-column">
                  {!isLoggedIn && (
                    <Link
                      to="register/seller"
                      className="btn btn-primary btn-block with-arrow"
                    >
                      <span>Become a seller</span>
                      <SVG src="arrow-right" className="arrow" />
                    </Link>
                  )}
                  {isLoggedIn && (
                    <Link
                      to="how-it-works/seller"
                      className="btn btn-primary btn-block with-arrow"
                    >
                      <span>How it works - seller</span>
                      <SVG src="arrow-right" className="arrow" />
                    </Link>
                  )}
                </div>
                <div className="button-column">
                  {!isLoggedIn && (
                    <Link
                      to="register/buyer"
                      className="btn btn-secondary btn-block with-arrow"
                    >
                      <span>Become a buyer</span>
                      <SVG src="arrow-right" className="arrow" />
                    </Link>
                  )}
                  {isLoggedIn && (
                    <Link
                      to="how-it-works/buyer"
                      className="btn btn-secondary btn-block with-arrow"
                    >
                      <span>How it works - buyer</span>
                      <SVG src="arrow-right" className="arrow" />
                    </Link>
                  )}
                </div>
              </div>
            </div>

            <div
              className={`col-md-6 home__our-way__video${
                videoPlaying ? " playing" : ""
              }`}
            >
              <Video
                title="Buyer process video"
                url="/static/uploads/buyer-video.mp4"
                poster="/static/uploads/buyer-video-poster.jpg"
                onToggle={this.handleVideoToggle}
              />
              <p className="text">
                Learn more about <strong>the buyer process</strong>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

OurWay.propTypes = {
  user: currentUserPropType,
};

OurWay.defaultProps = {
  user: {},
};

export default withAuth(OurWay);
