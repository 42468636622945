export const PROPERTIES_PER_PAGE = 6;

export const NUMERIC_FILTERS = {
  radius: true,
  price_range_min: true,
  price_range_max: true,
  bedrooms_min: true,
  bedrooms_max: true,
};

export const DEFAULT_FILTERS = {
  radius: 1,
  location: "",
  status: "publish",
  price_range_min: 0,
  price_range_max: 0,
  bedrooms_min: 0,
  bedrooms_max: 0,
  property_type: "",
};

export const SEARCH_RADIUS = [
  { value: 1, label: "This area only" },
  { value: 3, label: "Within 3 miles" },
  { value: 5, label: "Within 5 miles" },
  { value: 10, label: "Within 10 miles" },
  { value: 15, label: "Within 15 miles" },
  { value: 20, label: "Within 20 miles" },
  { value: 30, label: "Within 30 miles" },
  { value: 40, label: "Within 40 miles" },
];

export const PRICE_RANGE = [
  { value: 50000, label: "50,000" },
  { value: 60000, label: "60,000" },
  { value: 70000, label: "70,000" },
  { value: 80000, label: "80,000" },
  { value: 90000, label: "90,000" },
  { value: 100000, label: "100,000" },
  { value: 110000, label: "110,000" },
  { value: 120000, label: "120,000" },
  { value: 125000, label: "125,000" },
  { value: 130000, label: "130,000" },
  { value: 140000, label: "140,000" },
  { value: 150000, label: "150,000" },
  { value: 160000, label: "160,000" },
  { value: 170000, label: "170,000" },
  { value: 175000, label: "175,000" },
  { value: 180000, label: "180,000" },
  { value: 190000, label: "190,000" },
  { value: 200000, label: "200,000" },
  { value: 210000, label: "210,000" },
  { value: 220000, label: "220,000" },
  { value: 230000, label: "230,000" },
  { value: 240000, label: "240,000" },
  { value: 250000, label: "250,000" },
  { value: 260000, label: "260,000" },
  { value: 270000, label: "270,000" },
  { value: 280000, label: "280,000" },
  { value: 290000, label: "290,000" },
  { value: 300000, label: "300,000" },
  { value: 325000, label: "325,000" },
  { value: 350000, label: "350,000" },
  { value: 375000, label: "375,000" },
  { value: 400000, label: "400,000" },
  { value: 425000, label: "425,000" },
  { value: 450000, label: "450,000" },
  { value: 475000, label: "475,000" },
  { value: 500000, label: "500,000" },
  { value: 550000, label: "550,000" },
  { value: 600000, label: "600,000" },
  { value: 650000, label: "650,000" },
  { value: 700000, label: "700,000" },
  { value: 800000, label: "800,000" },
  { value: 900000, label: "900,000" },
  { value: 1000000, label: "1,000,000" },
  { value: 1250000, label: "1,250,000" },
  { value: 1500000, label: "1,500,000" },
  { value: 1750000, label: "1,750,000" },
  { value: 2000000, label: "2,000,000" },
  { value: 2500000, label: "2,500,000" },
  { value: 3000000, label: "3,000,000" },
  { value: 4000000, label: "4,000,000" },
  { value: 5000000, label: "5,000,000" },
  { value: 7500000, label: "7,500,000" },
  { value: 10000000, label: "10,000,000" },
  { value: 15000000, label: "15,000,000" },
  { value: 20000000, label: "20,000,000" },
];

export const PROPERTY_TYPES = [
  { value: "", label: "All Properties" },
  { value: "House", label: "House" },
  { value: "Flat / Apartment", label: "Flat / Apartment" },
  { value: "Bungalow", label: "Bungalow" },
  { value: "Commercial Property", label: "Commercial Property" },
  { value: "Character Property", label: "Character Property" },
  { value: "Land", label: "Land" },
  { value: "House / Flat Share", label: "House / Flat Share" },
  { value: "Retirement Property", label: "Retirement Property" },
  { value: "Mobile / Park homes", label: "Mobile / Park homes" },
  { value: "Garage / Parking", label: "Garage / Parking" },
  { value: "Guest House / Hotel", label: "Guest House / Hotel" },
  { value: "Private Hall", label: "Private Hall" },
];

export const NUMBER_OF_BEDROOMS = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
];

export const STATUSES = [
  { value: "publish", label: "Current Auctions" },
  { value: "sold", label: "Sold" },
];
