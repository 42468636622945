import React from "react";
import PropTypes from "prop-types";
import SVG from "components/elements/svg";
import TextContent from "../elements/text-content";

class ReasonData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
  }

  render() {
    return (
      <div className="reason-column">
        <div className="home__reasons__single">
          <div className={`icon ${this.props.svgClass}`}>
            <SVG src={this.props.svg} />
          </div>
          <div className="text">
            <p className="head">{this.props.title}</p>
            {!!this.props.description && (
              <TextContent content={this.props.description} />
            )}
            {!!this.props.children && (
              <TextContent>{this.props.children}</TextContent>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ReasonData.propTypes = {
  svg: PropTypes.string.isRequired,
  svgClass: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node,
};

ReasonData.defaultProps = {
  svgClass: "",
  description: "",
  children: null,
};

export default ReasonData;
