import React from "react";
import Link from "components/elements/link";

const CTATestimonials = () => (
  <section className="cta-banner cta-testimonials">
    <div className="container">
      <div className="cta-banner__content">
        <p className="section-heading">
          <span>
            Take a look at our <b>other property services</b>
          </span>
        </p>

        <Link
          className="btn btn-secondary btn-lg"
          to="https://hph-property.co.uk/"
        >
          Property Management
        </Link>
      </div>
    </div>
  </section>
);

export default CTATestimonials;
