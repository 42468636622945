import React from "react";
import Router from "next/router";
import PropTypes from "prop-types";
import Dropdown from "components/elements/dropdown";
import LocationInput from "components/elements/location-input";
import Svg from "components/elements/svg";
import { SEARCH_RADIUS } from "components/properties/properties-filters.defaults";

export default class PropertySearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: null,
      radius: null,
      formHasError: null,
    };
  }

  handleLocationChange = (value) => {
    if (value) {
      this.setState({ location: value, formHasError: false });
    } else {
      this.setState({ location: null, formHasError: false });
    }
  };

  handleRadiusChange = (value) => {
    if (value.length > 0) {
      this.setState({ radius: value[0] });
    } else {
      this.setState({ radius: null });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { location, radius } = this.state;
    if (location) {
      const query = { location, radius: SEARCH_RADIUS[0].value };

      if (radius) {
        query.radius = radius;
      }
      Router.push({ pathname: "/properties", query });
    } else {
      this.setState({
        formHasError: true,
      });
    }
  };

  render() {
    const { className, ...props } = this.props;
    const { formHasError } = this.state;
    return (
      <div className="property-search">
        <form
          {...props}
          className={`property-search-bar${className ? ` ${className}` : ""}`}
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <LocationInput
            className="property-search-bar__location"
            placeholder="e.g. 'Manchester', 'M4' or 'M4 4DE'"
            onInputChange={(value) => this.handleLocationChange(value)}
          />
          <Dropdown
            className="property-search-bar__radius"
            options={SEARCH_RADIUS}
            placeholder="Search radius"
            onChange={(value) => this.handleRadiusChange(value)}
          />
          <button className="property-search-bar__submit" type="submit">
            <Svg src="loop-icon" className="icon" />
            <span className="label">Search</span>
          </button>
        </form>
        {formHasError && <p>Please type an address or a postcode.</p>}
      </div>
    );
  }
}

PropertySearchBar.propTypes = {
  className: PropTypes.string,
};

PropertySearchBar.defaultProps = {
  className: "",
};
