import React from "react";
import debounce from "lodash/debounce";
import Collapse from "react-collapse";
import Slider from "components/elements/slider";
import Svg from "components/elements/svg";
import ReasonData from "components/home/reason-data";

class Reasons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse4: false,
      showSlider: false,
    };
    this.showSlider = debounce(this.showSlider.bind(this), 10);
  }

  componentDidMount() {
    this.showSlider();
    window.addEventListener("resize", this.showSlider);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.showSlider);
  }

  showSlider() {
    this.setState({ showSlider: window.innerWidth <= 768 });
  }

  changeSlide(interval) {
    this.slider.changeSlide(interval);
  }

  render() {
    return (
      <section className="home__reasons">
        <div className="container">
          <p className="section__title center">
            Doing things the <strong>Hammer Price</strong> way...
          </p>
          {!this.state.showSlider && (
            <div className="row">
              <ReasonData
                svg="reasons-1"
                svgClass="icon--1"
                title="Totally free to the seller"
                description="Our service is totally FREE to the seller,
                subject to our terms & conditions.
                We charge the buyer a reservation fee of 3.6% inc. VAT
                upon winning the auction (subject to a minimum fee of £3,600 inc. VAT)."
              />
              <ReasonData
                svg="reasons-2"
                svgClass="icon--2"
                title="Maximising on price"
                description="Our online auction process, accessible
              on any device 24/7, and open to mortgage
              and cash buyers, gives you the best chance of
              getting the maximum price for your property."
              />
              <ReasonData
                svg="reasons-3"
                svgClass="icon--3"
                title="Transparent process"
                description="Our process removes any third party interference
              and ensures that every bid is brought to the table
              and is visible to both buyer and seller."
              />
              <ReasonData
                svg="our-way-1"
                svgClass="icon--4"
                title="Mortgage buyers welcome"
              >
                <p>
                  Where possible we will open up our auctions for mortgage
                  buyers also. Please read the listing carefully to see if its
                  open to mortgage buyers as well as cash buyers. Sales on our
                  site are subject to contract with up to 90 days to complete
                  (check listing for completion terms). This means that those
                  needing a mortgage have enough time to raise the
                  finance.&nbsp;
                  {!this.state.collapse4 && (
                    <button
                      className="text-orange text-medium"
                      onClick={() => this.setState({ collapse4: true })}
                    >
                      Read more{" "}
                      <Svg src="chevron-down" className="icon-chevron" />
                    </button>
                  )}
                </p>
                <Collapse isOpened={this.state.collapse4}>
                  <p>Many of our buyers are mortgage buyers.</p>
                  <p>
                    {`Once you've won the auction our process follows the conventional route,
                    subject to our terms and conditions,
                    and you instruct a conveyancer.`}
                  </p>
                  <p>
                    We would strongly advise that you ensure you have your
                    finances sorted with your lender before you bid. You will
                    NOT be entitle to a refund of your reservation fee should
                    you withdraw due to financial reasons.
                  </p>
                  <p>Please bid responsibly and not beyond your means.</p>
                  <button
                    className="text-orange text-medium"
                    onClick={() => this.setState({ collapse4: false })}
                  >
                    Show less <Svg src="chevron-up" className="icon-chevron" />
                  </button>
                </Collapse>
              </ReasonData>
            </div>
          )}
          {this.state.showSlider && (
            <div>
              <button
                onClick={() => this.changeSlide(-1)}
                className="arrow arrow-left"
              >
                <Svg src="chevron-left" className="icon" />
              </button>
              <Slider
                infinite
                autoplay
                ref={(el) => {
                  this.slider = el;
                }}
              >
                <ReasonData
                  svg="reasons-1"
                  svgClass="icon--1"
                  title="Totally free to the seller"
                  description="Our service is totally FREE to the seller,
              subject to our terms & conditions.
              We charge the buyer a reservation fee of 3.6% inc. VAT
              upon winning the auction (subject to a minimum fee of £3,600 inc. VAT)."
                />
                <ReasonData
                  svg="reasons-2"
                  svgClass="icon--2"
                  title="Maximising on price"
                  description="Our 28 day online auction process, accessible
              on any device 24/7, and open to mortgage
              and cash buyers gives you the best chance of
              getting the maximum price for your property."
                />
                <ReasonData
                  svg="reasons-3"
                  svgClass="icon--3"
                  title="Transparent process"
                  description="Our process removes any third party interference
              and ensures that every bid is brought to the table
              and is visible to both buyer and seller."
                />
                <ReasonData
                  svg="our-way-1"
                  svgClass="icon--4"
                  title="Mortgage buyers welcome"
                >
                  <p>
                    Sales on our site are subject to contract with 90 days to
                    complete. This means that those needing a mortgage have
                    enough time to raise the finance.&nbsp;
                    {!this.state.collapse4 && (
                      <button
                        className="text-orange text-medium"
                        onClick={() => this.setState({ collapse4: true })}
                      >
                        Read more{" "}
                        <Svg src="chevron-down" className="icon-chevron" />
                      </button>
                    )}
                  </p>
                  <Collapse isOpened={this.state.collapse4}>
                    <p>Many of our buyers are mortgage buyers.</p>
                    <p>
                      {`Once you've won the auction our process follows the conventional route,
                    subject to our terms and conditions,
                    and you instruct a conveyancer.`}
                    </p>
                    <p>
                      We would strongly advise that you ensure you have your
                      finances sorted with your lender before you bid. You will
                      NOT be entitle to a refund of your reservation fee should
                      you withdraw due to financial reasons.
                    </p>
                    <p>Please bid responsibly and not beyond your means.</p>
                    <button
                      className="text-orange text-medium"
                      onClick={() => this.setState({ collapse4: false })}
                    >
                      Show less{" "}
                      <Svg src="chevron-up" className="icon-chevron" />
                    </button>
                  </Collapse>
                </ReasonData>
              </Slider>
              <button
                onClick={() => this.changeSlide(1)}
                className="arrow arrow-right"
              >
                <Svg src="chevron-right" className="icon" />
              </button>
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default Reasons;
