import React from "react";
import asPage from "components/core/as-page";
import Header from "components/core/header";
import Layout from "components/core/layout";
import Svg from "components/elements/svg";
import CTATestimonials from "components/home/cta-testimonials";
import CurrentAuctionsSlider from "components/home/current-auctions-slider";
import OurWay from "components/home/our-way";
import PropertySearchBar from "components/home/property-search-bar";
import Reasons from "components/home/reasons";
import RecentlySold from "components/home/recently-sold";
import Integrations from "components/partials/integrations";
import scrollToElem from "helpers/scroll-to-elem";

const scrollToContent = (e: any) => {
  e.preventDefault();
  e.stopPropagation();
  scrollToElem(document.querySelector("#home__content"));
};

type Props = Record<string, never>;
type State = {
  showVideo: boolean;
};

class HomePage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showVideo: false,
    };
  }

  componentDidMount() {
    if (window.innerWidth >= 1024) {
      this.setState({ showVideo: true });
    }
  }

  render() {
    const { showVideo } = this.state;
    return (
      <Layout className="home" title="Home">
        <Header background="/static/uploads/video-cover.jpg">
          <div className="home__video-container">
            {showVideo && (
              <video
                autoPlay
                loop
                className="player"
                muted
                poster="/static/uploads/video-cover.jpg"
              >
                <source src="/static/uploads/video.mp4" type="video/mp4" />
              </video>
            )}
          </div>
          <div className="home__hero container">
            <h1 className="home__title">
              <span>THE ONLINE PROPERTY AUCTION</span>
            </h1>
            <p className="home__subtitle">
              Sell your property for free, at the best possible price
            </p>
            <p className="home__subtitle">and in the most transparent way</p>
            <PropertySearchBar />
            <a
              className="home__scroll-down"
              href="#home__content"
              onClick={scrollToContent}
              title="Scroll down"
            >
              <Svg className="icon" src="chevron-down" />
            </a>
          </div>
        </Header>

        <div id="home__content">
          <Reasons />

          <OurWay />

          <CurrentAuctionsSlider />

          <RecentlySold />

          <CTATestimonials />

          <Integrations />
        </div>
      </Layout>
    );
  }
}

export default asPage(HomePage);
