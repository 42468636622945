import React from "react";
import debounce from "lodash/debounce";
import InfoSection from "components/elements/info-section";
import Preloader from "components/elements/preloader";
import PropertyBox from "components/elements/property-box";
import Slider from "components/elements/slider";
import Svg from "components/elements/svg";
import Api from "services/api";
import AuthService from "services/auth";
import TextContent from "../elements/text-content";

class CurrentAuctionsSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      properties: [],
      loading: true,
      currentSlide: 1,
      perSlide: 3,
    };
    this.api = new Api();
    this.setItemsPerSlide = debounce(this.setItemsPerSlide.bind(this), 10);
  }

  componentDidMount() {
    const { pusher } = window;
    this.socket = pusher.subscribe("properties-channel");
    this.socket.bind("property.status.updated", (event) => {
      if (event.data.property.newStatus !== "publish") {
        const { properties } = this.state;
        const newProperties = properties.filter(
          (item) => item.id !== event.data.property.id,
        );
        this.setState({ properties: newProperties });
      }
    });
    this.fetchProperties(1);
    this.setItemsPerSlide();
    window.addEventListener("resize", this.setItemsPerSlide);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setItemsPerSlide);
    if (this.socket) {
      this.socket.unbind();
    }
  }

  onPropertyUpdate = (property) => {
    const { properties } = this.state;
    const newProperties = properties.map((elem) => {
      if (elem.id === property.id) {
        return property;
      }
      return elem;
    });
    this.setState({ properties: newProperties });
    return true;
  };

  setItemsPerSlide() {
    this.setState({ perSlide: window.innerWidth > 768 ? 3 : 1 });
  }

  fetchProperties(page) {
    AuthService.getTokens().then((tokens) => {
      const data = {
        page,
        perPage: -1,
        filters: {
          status: "publish",
        },
      };
      this.api.get("/properties", data, tokens).then((response) => {
        this.setState({
          properties: response.properties || [],
          loading: false,
          currentSlide: 1,
        });
      });
    });
  }

  changeSlide(interval) {
    this.slider.changeSlide(interval);
  }

  render() {
    const { perSlide } = this.state;
    const propertiesLength = this.state.properties.length;

    return (
      <div className="home__current-auctions">
        <h3 className="section-heading">
          Take part in <strong>Current Auctions</strong>
        </h3>
        <div className="home__current-auctions__inner">
          <Preloader show={this.state.loading} />
          <div className="container">
            {this.state.properties.length > perSlide && (
              <button
                onClick={() => this.changeSlide(-1)}
                className="arrow arrow-left"
              >
                <Svg src="chevron-left" className="icon" />
              </button>
            )}
            <div className="row">
              <Slider
                ref={(slider) => {
                  this.slider = slider;
                }}
                infinite
                perSlide={this.state.perSlide}
              >
                {this.state.properties.map((property) => (
                  <div
                    key={property.id}
                    className="home__current-auctions__property"
                  >
                    <PropertyBox
                      countdownTheme="dark"
                      property={property}
                      onPropertyUpdate={this.onPropertyUpdate}
                    />
                  </div>
                ))}
              </Slider>
            </div>
            {this.state.properties.length > perSlide && (
              <button
                onClick={() => this.changeSlide(1)}
                className="arrow arrow-right"
              >
                <Svg src="chevron-right" className="icon" />
              </button>
            )}
          </div>
          {!propertiesLength && (
            <InfoSection icon="home-thin" title="No current auctions">
              <TextContent>
                <p>
                  Please come back soon, as properties will be listed in the
                  near future.
                </p>
              </TextContent>
            </InfoSection>
          )}
        </div>
      </div>
    );
  }
}

export default CurrentAuctionsSlider;
